<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Quantidade de Funcionários</h1>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <router-link :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Novo registro</span>
          </router-link>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <label for="obra" class="block text-sm font-medium">Obra</label>
          <select v-model="obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
          </select>
      </div>
      <div class="col-span-12 md:col-span-3 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Mês da leitura</label>
        <select v-model="mes" id="mes" name="mes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option :value="1">Janeiro</option>
          <option :value="2">Fevereiro</option>
          <option :value="3">Março</option>
          <option :value="4">Abril</option>
          <option :value="5">Maio</option>
          <option :value="6">Junho</option>
          <option :value="7">Julho</option>
          <option :value="8">Agosto</option>
          <option :value="9">Setembro</option>
          <option :value="10">Outubro</option>
          <option :value="11">Novembro</option>
          <option :value="12">Dezembro</option>
        </select>
      </div>
      <div class="col-span-12 md:col-span-3 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Ano da leitura</label>
        <select v-model="ano" id="ano" name="ano" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option v-for="idx in 6" :key="idx - 6" :value="$moment().subtract(6 - idx, 'years').format('YYYY')">{{ $moment().subtract(6 - idx, 'years').format('YYYY') }}</option>
          <option v-for="idx in 5" :key="idx" :value="$moment().add(idx, 'years').format('YYYY')">{{ $moment().add(idx, 'years').format('YYYY') }}</option>
        </select>
      </div>
      <div class="col-span-12 md:col-span-3 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro </label>
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} registros cadastrados </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Obra
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantidade
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Usuário
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.obra ? item.obra.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.mes }}/{{item.ano }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.qtd ? item.qtd : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.usuario ? item.usuario.name : ''}}
                      </div>
                    </div>
                  </td>
                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                    <router-link 
                    :to="`/${route}/form/${item._id}`" 
                    class="text-blue-500 rounded py-1 px-4">
                    Editar
                    </router-link>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'qtdfunc',
      list: [],
      mes: '',
      ano: '',
      obra: null,
      obras: [],
      query: { page: 1, limit: 10, skip: 0  },
      perPage: 10,
      total: 0,
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { obra: this.obra, mes: this.mes, ano: this.ano, skip: this.query.skip, limit: this.query.limit, });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Removido com sucesso!");
      this.start();
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, obra: this.obra, mes: this.mes, ano: this.ano };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.mes) this.mes = this.$route.query.mes;
    if(this.$route.query.ano) this.ano = this.$route.query.ano;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    await this.start();
  },
  async beforeMount() {
    const reqobras = await this.$http.post('/v1/obras/list');
    this.obras = reqobras.data.data;

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.mes) this.mes = this.$route.query.mes;
    if(this.$route.query.ano) this.ano = this.$route.query.ano;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>